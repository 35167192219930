import { ThankYouScreen } from '@/components/invite/ThankYouScreen'
import { InviteContext } from '@/utils/context/Invite.context'
import { useInviteGrantStatus } from '@/utils/helpers/useGranted'
import { Box, Button, Checkbox, Divider, Image, Loader, Paper, Portal, Radio, Text, TextInput, Textarea, Transition, useMantineColorScheme } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { ArrowRight, InfoCircle, UserCircle } from 'tabler-icons-react'
import { Agency, IntakeCardDetailsAnswer, IntakeFieldAnswer, IntakeForm, IntakeMultiSelectAnswer, IntakeQuestion } from '../../../../../types/global'
import api from '@/utils/api'
import { useElementSize } from '@mantine/hooks'
import { isPromise } from 'util/types'
import { ThemeContext } from '@/utils/context/Theme.context'
import lang from '@/../public/translations/intake.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { InviteBodyContainer } from '.'
import Footer from '@/components/invite/Footer'
import { IntakeFormQA } from '@/components/invite/IntakeFormQA'
import Error from '@/components/Error'
import cardValidator from 'card-validator'

// const Question = ({ question, i, answer, setAnswer }: { question: IntakeQuestion, i: number, answer: string | string[], setAnswer: (a: string | string[]) => void }) => {
//     const { lang: l } = useContext(InviteClientContext)
//     return (
//         <Box>
//             <Box className='flex' style={{ gap: 5 }}>
//                 <Text span fw={500} c="dimmed">{i + 1}.</Text>
//                 <Box>
//                     <Text fw={500}>{question.title} <Text span c={"red"} fw={600}>{question.required ? "*" : ""}</Text></Text>
//                     {question.description
//                         ? <Text fz="xs" c="dimmed">{question.description}</Text>
//                         : null
//                     }

//                 </Box>
//             </Box>

//             {question.type === "Input"
//                 ? <TextInput mt={5} placeholder={question.placeholder || lang.placeholders.input[l]} onChange={(e) => setAnswer(e.target.value)} />
//                 : null
//             }

//             {question.type === "Textbox"
//                 ? <Textarea radius={10} minRows={2} autosize mt={5} placeholder={question.placeholder || lang.placeholders.textbox[l]} onChange={(e) => setAnswer(e.target.value)} />
//                 : null
//             }

//             {question.type === "Multi-select"
//                 ? <Box ml={10} className='flex fdc' style={{ gap: 10 }} mt={5}>
//                     {question.options.map((o) => <Checkbox
//                         label={o}
//                         checked={answer?.includes(o)}
//                         onChange={(e) => {
//                             if (e.target.checked) {

//                                 setAnswer(answer ? [...answer, o] : [o])
//                             } else {
//                                 const newAnswer = [...answer]
//                                 newAnswer.splice(answer.indexOf(o), 1)
//                                 setAnswer(newAnswer)
//                             }
//                         }}
//                     />)}
//                 </Box>
//                 : null
//             }

//             {question.type === "Option"
//                 ? <Box ml={10} className='flex fdc' style={{ gap: 10 }} mt={5}>
//                     {question.options.map((o) => <Radio
//                         label={o}
//                         checked={answer === o}
//                         onChange={(e) => {
//                             if (e.target.checked) {
//                                 setAnswer(o)
//                             } else {
//                                 setAnswer("")
//                             }
//                         }}
//                     />)}
//                 </Box>
//                 : null
//             }

//         </Box>
//     )
// }

export const Form = ({ form }: { form?: IntakeForm | null }) => {
    const router = useRouter()
    const invite = useContext(InviteContext)
    const { lang: l } = useContext(InviteClientContext)
    const theme = useContext(ThemeContext)
    const [answers, setAnswers] = useState<Record<string, IntakeFieldAnswer>>({})
    const [formMounted, setFormMounted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [DEMO_fakeSubmitPopover, setFakeSubmitPopover] = useState(false)
    const [error, setError] = useState("")
    const { setColorScheme } = useMantineColorScheme();

    useEffect(() => {
        if (theme.theme === "dark") {
            setColorScheme("dark")
        } else {
            setColorScheme("light")
        }
    }, [theme, invite])

    const { height, ref } = useElementSize()
    useEffect(() => {
        if (!form) return;
        setAnswers(form.questions.reduce((prev, current) => ({
            ...prev, [current.id!]: current.type === "Multi-select" ? [] : (current.type === "Card Details" ? {
                number: "",
                cvv: "",
                expires: ""
            } : "")
        }), {}))
        setFormMounted(true)
    }, [form])

    console.log(answers)

    return (
        <>
            <Transition mounted={DEMO_fakeSubmitPopover} transition={"fade-up"} duration={350}>
                {(styles) => {
                    return (
                        <Portal target={"#activeDemoContainer"}>
                            <Box className='flex aic jcc fdc' style={{ position: "absolute", top: 0, left: 0, width: "calc(100%)", height: "100%", zIndex: 900, backdropFilter: "blur(20px)", ...styles }} bg={"rgba(255,255,255,.25)"}>
                                <Box className='flex aic'>
                                    <Box w={40} h={40} style={{ borderRadius: 10 }} bg={"gray.1"} className='flex aic jcc'>
                                        <UserCircle size={20} />
                                    </Box>
                                    {/* <Image alt={account} width={40} height={40} style={{ borderRadius: 10, objectFit: "contain", backgroundColor: "#fff", padding: 5, boxShadow: "0px 2px 2px rgba(0,0,0,.1)" }} src={`/images/logos/${account?.toLowerCase().replaceAll(" ", "_")}.png`} /> */}
                                    <Box w={30} style={{ borderTop: "2px dashed rgba(0,0,0,.35)" }} />
                                    <Image alt={"Zapier logo"} width={40} height={40} style={{ borderRadius: 10, objectFit: "contain", backgroundColor: "#fff", padding: 5, boxShadow: "0px 2px 2px rgba(0,0,0,.1)" }} src={`/images/logos/zapier.png`} />
                                </Box>
                                <Text fw={600} mt={15}>Intake form submitted</Text>
                                <Text maw={450} ta={"center"} fz="sm">You can view your clients answers on AgencyAccess, or forward them to your Zapier workflow.</Text>
                                <Button mt={10} rightSection={<ArrowRight size={18} />} onClick={() => {
                                    setFakeSubmitPopover(false)
                                }}>Continue</Button>
                            </Box>
                        </Portal>
                    )
                }}
            </Transition>
            <Box px={30} w="100%" style={{ transition: "all .3s", height: height || 100 }}>
                {!!form
                    ? <Transition mounted={formMounted} keepMounted transition={"fade"}>
                        {(styles) => (
                            <Box ref={ref} style={{ ...styles }}>
                                <Text fw={700} fz="sm" c="dimmed">{form.questions.length} {form.questions.length === 1 ? lang.questionCounter.single[l] : lang.questionCounter.multiple[l]}</Text>
                                <Text fz={25} lh={1} fw={600}>{theme.displayName || (router.query.preview && router.query.agencyName ? router.query.agencyName : invite.agency.name)} {lang.title[l]}</Text>

                                <Box mt={20} className='flex fdc' style={{ gap: 15 }}>
                                    {form.questions.sort((a, b) => (a.order || 0) - (b.order || 0)).map((q, i) => (
                                        <>
                                            {!!i ? <Divider opacity={.5} /> : null}
                                            <IntakeFormQA answer={answers[q.id!]} question={q} i={i} setAnswer={(a) => {
                                                setAnswers(prev => ({
                                                    ...prev,
                                                    [q.id!]: a
                                                }))
                                            }} />
                                        </>
                                    ))}
                                </Box>
                                <Button color={theme.color || undefined} loading={loading} disabled={
                                    !!router.query.preview ||
                                    !!form.questions.filter((q, i) => {
                                        if (!q.required) return false;
                                        switch (q.type) {
                                            case 'Input': return !answers[q.id!]
                                            case 'Textbox': return !answers[q.id!]
                                            case 'Option': return !answers[q.id!]
                                            case 'Multi-select': return !(answers[q.id!] as IntakeMultiSelectAnswer)?.length
                                            case 'Card Details': {
                                                const answer = answers[q.id!] as IntakeCardDetailsAnswer
                                                return !answer?.cvv || !answer?.expires || (!answer?.number || !cardValidator.number(answer?.number).isPotentiallyValid)
                                            }
                                        }
                                    }).length
                                } mt={25} size="md" fullWidth rightSection={<ArrowRight size={20} />} onClick={() => {
                                    if (invite.DEMO) return setFakeSubmitPopover(true);
                                    setLoading(true)
                                    setError("")
                                    api.post("/intake/form/submit", {
                                        inviteID: invite.id,
                                        answers: answers
                                    }).then((res) => {
                                        setLoading(false)
                                        if (res.error) {
                                            return setError(res.msg)
                                        }
                                    })
                                }}>{lang.submitButton[l]}</Button>
                            </Box>
                        )}
                    </Transition>
                    : <Box className='flex aic jcc' w="100%" h="100%">
                        <Loader mx="auto" size={"sm"} />
                    </Box>
                }
            </Box>
            <Box px={30} w="100%" mt={10}>
                <Error>{error}</Error>

            </Box>
        </>
    )
}

export default function Intake() {
    const router = useRouter()
    const invite = useContext(InviteContext)
    const isGranted = useInviteGrantStatus(invite?.id, invite)
    const [previewData, setPreviewData] = useState<IntakeForm | null>(null)
    const [form, setForm] = useState<IntakeForm | null | undefined>(undefined)
    const [error, setError] = useState("")

    useEffect(() => {
        if (router.query.preview) {
            console.log(JSON.parse(router.query.preview as string))
            setPreviewData(JSON.parse(router.query.preview as string))
        }
    }, [router])

    useEffect(() => {
        if (!isGranted && invite) {
            router.push(`/i/${invite.id}`)
            return
        }
    }, [isGranted])

    useEffect(() => {
        if (isGranted && invite.intakeForm?.requested && !invite.intakeForm.compelte) {
            api.get<IntakeForm>("/intake/form", {
                inviteID: invite.id
            }).then((res) => {
                if (res.error) {
                    return setError(res.msg)
                }
                setForm(res.data)
            })
        }
    }, [isGranted])

    if (previewData) {
        return (
            <InviteBodyContainer>
                <Form form={previewData} />
            </InviteBodyContainer>
        )
    }

    if (invite?.intakeForm?.compelte) {
        return <ThankYouScreen invite={invite} />
    }
    if (!invite) return null;

    return (
        <>
            <InviteBodyContainer>
                {error
                    ? <>
                        <InfoCircle color='var(--mantine-color-red-6)' />
                        <Text fw={600} mt={5}>Oops!</Text>
                        <Text fz="sm" ta={"center"} c="dimmed">{error}</Text>
                        <Link href={`/i/${invite.id}`}>
                            <Button variant="default" mt={10}>Return to invite</Button>
                        </Link>
                    </>
                    : <>
                        {!invite?.intakeForm?.requested
                            ? <>
                                <InfoCircle color='var(--mantine-color-red-6)' />
                                <Text fw={600} mt={5}>You have not been asked to fill out an intake form.</Text>
                                <Text ta={"center"} fz="sm" c="dimmed">If you think this is a mistake, contact {invite.agency.name}</Text>
                                <Link href={`/i/${invite.id}`}>
                                    <Button variant="default" mt={10}>Return to invite</Button>
                                </Link>
                            </>
                            : <>
                                {!isGranted
                                    ? <>
                                        <InfoCircle color='var(--mantine-color-red-6)' />
                                        <Text fw={600} mt={5}>Grant access to continue</Text>
                                        <Text ta={"center"} fz="sm" c="dimmed">You must grant access to all of the requested services before filling out the intake form</Text>
                                        <Link href={`/i/${invite.id}`}>
                                            <Button variant="default" mt={10}>Return to invite</Button>
                                        </Link>
                                    </>
                                    : <>
                                        <Form form={form} />
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </InviteBodyContainer>
            <Footer />
        </>
    )
}
